<template>
	<div :style="{ position: 'relative', marginLeft: '10px', width: '100%' }">
		<div :style="{ width: '100%' }" class="mainDiv">
			<div class="subDiv">Status</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px', display: 'flex', justifyContent: 'center' }">
					<a-checkbox v-model:checked="formData.active" :disabled="checkBoxDisable" name="type" :style="{ border: checkBoxDisable ? '1px solid rgba(0, 0, 0, 0.25)' : '1px solid #44142a' }" class="checkBox" @click="activeClick">Active</a-checkbox>
					<a-checkbox v-model:checked="formData.adminBlocked" :disabled="checkBoxDisable" name="type" :style="{ border: checkBoxDisable ? '1px solid rgba(0, 0, 0, 0.25)' : '1px solid #44142a' }" class="checkBox" @click="blockedClick">Admin Blocked</a-checkbox>
				</div>
			</div>
		</div>

		<div :style="{ width: '100%', marginTop: '15px' }" class="mainDiv">
			<div class="subDiv">Birth Details</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Date of birth" :style="{ marginBottom: '10px' }">
						<a-date-picker style="width: 100%" v-model:value="formData.selectDob" format="DD-MMM-YYYY" />
					</a-form-item>
					<a-form-item label="Time of birth" :style="{ marginBottom: '10px' }">
						<a-time-picker style="width: 100%" v-model:value="formData.tob" />
					</a-form-item>
					<a-form-item label="Place of birth" :style="{ marginBottom: '10px' }">
						<a-select v-model:value="formData.pob" allow-clear show-search placeholder="Search a place" @select="select" :options="locationItems" @search="search" :style="{ maxWidth: '260px' }">
							<template v-slot:notFoundContent>
								<Spin size="small" v-if="loading" />
								<Empty :image="Empty.PRESENTED_IMAGE_SIMPLE" v-else />
							</template>
						</a-select>
					</a-form-item>
					<a-form-item label="Co-ordinates" :style="{ marginBottom: '10px' }">
						<a-input v-model:value="formData.geoBirth" disabled />
					</a-form-item>
				</div>
			</div>
		</div>

		<div :style="{ width: '100%', marginTop: '10px' }" class="mainDiv">
			<div class="subDiv">Notification</div>
			<div :style="{ padding: '15px' }">
				<div :style="{ marginTop: '15px' }">
					<a-checkbox-group v-model:value="formData.push_notifications" :style="{ marginTop: '10px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }">
						<a-checkbox class="checkBox" value="been_matched" name="type">Matches</a-checkbox>
						<a-checkbox class="checkBox" value="been_liked" name="type">Likes</a-checkbox>
						<a-checkbox class="checkBox" value="been_superliked" name="type">Super-likes</a-checkbox>
						<a-checkbox class="checkBox" value="message_received" name="type">Message</a-checkbox>
					</a-checkbox-group>
				</div>
			</div>
		</div>

		<div :style="{ width: '100%', marginTop: '15px' }" class="mainDiv">
			<div class="subDiv">Current Location</div>
			<div :style="{ padding: '10px' }">
				<div :style="{ marginTop: '10px' }">
					<a-form-item label="Location" :style="{ marginBottom: '10px' }">
						<a-input v-model:value="formData.location" :disabled="true" />
					</a-form-item>
					<a-form-item label="Co-ordinates" :style="{ marginBottom: '10px' }">
						<a-input v-model:value="formData.geoCordinate" :disabled="true" />
					</a-form-item>
				</div>
			</div>
		</div>
	</div>
</template>
<script lang="ts" setup>
	import { ref } from "vue";
	import dayjs, { Dayjs } from "dayjs";
	import { getGeoCordApi, getLocationApi } from "@/services/api/auth";
	import { Empty, Spin } from "ant-design-vue";
	const { formData } = defineProps(["formData"]);
	// const dateFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
	// const dob: any = ref<Dayjs>(formData?.dob ? dayjs(formData?.dob, dateFormat) : dayjs());
	// const tob = ref<Dayjs>(dayjs(formData?.tob ? formData?.tob : dayjs().startOf("minute"), "HH:mm:ss"));
	const checkBoxDisable = ref(false);
	const loading = ref(false);
	const locationItems: any = ref([]);

	const getUserData: any = localStorage.getItem("user");
	const userData = JSON.parse(getUserData);
	checkBoxDisable.value = userData?._id == formData?._id ? true : false;

	// const setDob = () => {
	// 	formData.dob = dob.value && `${dob.value.format("YYYY-MM-DD")}T${tob.value ? tob.value.format("HH:mm:ss.SSS[Z]") : "00:00:00.000Z"}`;
	// };
	// const setTob = () => {
	// 	formData.dob = dob.value && `${dob.value.format("YYYY-MM-DD")}T${tob.value ? tob.value.format("HH:mm:ss.SSS[Z]") : "00:00:00.000Z"}`;
	// };

	const activeClick = () => {
		formData.adminBlocked = false;
	};

	const blockedClick = () => {
		formData.active = false;
	};

	const select = async (index: number, record: any) => {
		try {
			const res = await getGeoCordApi(record.label);
			const firstResult = res?.data?.results[0];
			const latitude = firstResult.geometry.location.lat;
			const longitude = firstResult.geometry.location.lng;
			formData.geoCordinate = `${latitude.toFixed(4)} ${longitude.toFixed(4)}`;
		} catch (error) {
			console.log(error);
		}
	};

	const locationList = async (input: string) => {
		try {
			if (input) {
				const res = await getLocationApi(input);
				const locationData = res?.data?.predictions;
				locationItems.value = locationData.map((e: any, i: number) => ({
					label: e.description,
					value: e.description,
					key: i,
				}));
				loading.value = false;
			}
		} catch (error) {
			loading.value = false;
			console.log(error);
		}
	};

	const search = (input: string) => {
		loading.value = true;
		setTimeout(() => {
			locationList(input);
		}, 1000);
	};
</script>
<style scoped>
	.mainDiv {
		border: 2px solid rgb(190, 190, 190);
		position: relative;
		border-radius: 8px;
		.ant-form-item {
			margin-bottom: 8px !important;
		}
	}
	.subDiv {
		display: flex;
		position: absolute;
		top: -12px;
		background: #ffff;
		margin-left: 20px;
		padding: 0 5px;
		font-weight: 700;
	}
	.checkBox {
		padding: 6px 15px;
		border-radius: 5px;
		width: 45%;
		color: #44142a;
		border: 1px solid #44142a;
		margin: 7px;
	}
</style>
