<template>
	<div v-if="!loading" :style="{ fontSize: '25px', margin: '10px 10px 15px 10px', display: 'flex', justifyContent: 'space-between' }">
		<a-breadcrumb class="heading" separator="|">
			<a-breadcrumb-item :style="{ color: '#44142a', cursor: 'pointer', fontSize: '16px', fontWeight: '700' }" @click="closeDetails"><TeamOutlined /> {{ heading }}</a-breadcrumb-item>
			<a-breadcrumb-item :style="{ fontSize: '16px', fontWeight: '700' }">{{ formData._id }}</a-breadcrumb-item>
		</a-breadcrumb>
		<a-form-item label="Joined">
			<a-typography-text class="ant-form-text" type="secondary">{{ formData.createdAt && dayjs.utc(formData.createdAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
		</a-form-item>
		<a-form-item label="Last Edited">
			<a-typography-text class="ant-form-text" type="secondary"> {{ formData.modifiedAt && dayjs.utc(formData.modifiedAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
		</a-form-item>
		<a-form-item label="Last Login">
			<a-typography-text class="ant-form-text" type="secondary">{{ formData.login && dayjs.utc(formData.login).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
		</a-form-item>
		<CloseCircleOutlined :style="{ marginBottom: '20px' }" @click="closeDetails" />
	</div>
	<div v-if="loading" class="loading-wrapper">
		<a-spin />
	</div>
	<a-form v-if="!loading" :label-col="labelCol" :wrapper-col="wrapperCol" layout="horizontal" :disabled="componentDisabled">
		<div :style="{ display: 'grid', justifyContent: 'center' }">
			<div :style="{ display: 'flex', padding: '5px' }">
				<UserProfile :formData="formData" :closeDrawer="closeDrawer" :openDrawer="openDrawer" :openDrawerValue="openDrawerValue"></UserProfile>
				<UserNotification :formData="formData"></UserNotification>
				<UserSetting :formData="formData" :openDrawerValue="openDrawerValue"></UserSetting>
			</div>
			<div :style="{ display: 'flex', justifyContent: 'space-between', margin: '20px' }">
				<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }" @click="previousDetails">
					<LeftCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" />
					<div :style="{ marginLeft: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Previous<br />User</div>
				</div>
				<div>
					<a-button :disabled="false" size="large" @click="submitUserDetails" type="primary" :style="{ width: '200px', marginTop: '5px' }">Save</a-button>
				</div>
				<div :style="{ display: 'flex', alignItems: 'center', cursor: 'pointer' }" @click="nextDetails">
					<div :style="{ marginRight: '10px', fontSize: '16px', fontWeight: 700, color: '#44142a' }">Next<br />User</div>
					<RightCircleOutlined :style="{ fontSize: '40px', color: '#44142a' }" />
				</div>
			</div>
		</div>
	</a-form>
</template>

<script lang="ts" setup>
	import { CloseCircleOutlined, LeftCircleOutlined, RightCircleOutlined, TeamOutlined } from "@ant-design/icons-vue";
	import { ref } from "vue";
	import UserProfile from "@/components/MembersDetails/UserProfile.vue";
	import UserSetting from "@/components/MembersDetails/UserSetting.vue";
	import UserNotification from "@/components/MembersDetails/UserNotification.vue";
	import { getUser, getUserMap, userSample } from "@/services/api/user";
	import { updateUserApi } from "@/services/api/auth";
	import router from "@/router";
	import { convertTimestamp, openNotificationWithIcon } from "@/utils";
	import dayjs from "dayjs";
	import { DISABLE_FORM } from "@/.config";

	const componentDisabled = ref(DISABLE_FORM);
	const loading = ref(false);
	const user = ref(userSample);
	const formData = ref<any>({});
	const labelCol = { style: { width: "130px" } };
	const wrapperCol = { span: 14 };
	const openDrawerValue = ref(false);
	const submitUserDetails = async () => {
		formData.value.roles = ["active"];
		formData.value.dob = `${formData.value.selectDob.format("YYYY-MM-DD")}T${formData.value.tob.format("HH:mm:ss.SSS[Z]")}`;

		const geoCordinate = formData.value.geoCordinate.split(" ");
		formData.value.geo.lat = geoCordinate[0];
		formData.value.geo.lng = geoCordinate[1];
		formData.value.profession_public = formData.value.profession_public_element == "public" ? true : false;
		formData.value.residence_public = formData.value.residence_public_element == "public" ? true : false;
		formData.value.orientation_public = formData.value.orientation_public_element == "public" ? true : false;
		formData.value.relationship_public = formData.value.relationship_public_element == "public" ? true : false;
		formData.value.adminBlocked && formData.value.roles.push("blocked");
		formData.value.height = formData.value.height / 100;

		for (const key in formData.value) {
			const element = formData.value[key];
			let foundPreference = false;
			for (let i = 0; i < formData.value.preferences.length; i++) {
				const preferencesElement = formData.value.preferences[i];
				if (preferencesElement.key === key) {
					preferencesElement.value = element;
					foundPreference = true;
					break;
				}
			}

			if (!foundPreference && (key == "birth_chart_type" || key == "public" || key == "jungian_type" || key == "birth_chart_symbol" || key == "birth_chart_sign" || key === "height" || key === "diet_options" || key === "religiosity" || key === "drinking" || key == "smoking" || key == "workout" || key == "genders")) {
				formData.value.preferences.push({ type: "string", key, value: element });
			}
		}

		await updateUserApi(router.currentRoute.value.params.id, formData.value);
		router.back();
	};

	const fetchData = async (idValue: string = "") => {
		try {
			loading.value = true;
			const id: any = idValue || router.currentRoute.value.params.id;
			if (!id) {
				return;
			}
			const apiRes: any = await getUser(id);
			user.value = await getUserMap(apiRes?.user);
			formData.value = user.value;
			loading.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	fetchData();

	const openDrawer = () => {
		openDrawerValue.value = true;
	};

	const path = window.location.href.includes("members") ? "members" : "report";
	const heading = ref(path === "members" ? "Members" : "Reporter");

	const closeDrawer = () => {
		openDrawerValue.value = false;
	};
	const closeDetails = async () => {
		if (path.includes("members")) {
			router.push("/members");
		} else if (path.includes("report")) {
			router.push("/reported");
		}
	};

	const previousDetails = async () => {
		const ids = localStorage.getItem(path === "members" ? "ids" : "reportedIds");
		if (!ids) {
			openNotificationWithIcon("error", "Previous Member not available.");
			return;
		}
		const idsData = JSON.parse(ids);
		const currentIndex = await idsData.indexOf(router.currentRoute.value.params.id);
		const previousUser = idsData[currentIndex - 1];
		if (!previousUser) {
			openNotificationWithIcon("error", "Previous Member not available.");
			return;
		}
		router.push(`/members/${previousUser}`);
		fetchData(previousUser);
	};

	const nextDetails = async () => {
		const ids = localStorage.getItem(path === "members" ? "ids" : "reportedIds");
		if (!ids) {
			openNotificationWithIcon("error", "Next Member not available.");
			return;
		}
		const idsData = JSON.parse(ids);
		const currentIndex = await idsData.indexOf(router.currentRoute.value.params.id);
		const nextUser = idsData[currentIndex + 1];
		if (!nextUser) {
			openNotificationWithIcon("error", "Next Member not available.");
			return;
		}
		router.push(`/members/${nextUser}`);
		fetchData(nextUser);
	};
</script>
<style scoped>
	.loading-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100vh;
	}
</style>
