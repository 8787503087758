<template>
	<div :style="{ padding: '20px 20px 0px 20px' }">
		<div class="table-operations">
			<a-breadcrumb class="heading" separator="/">
				<a-breadcrumb-item :style="{ color: '#44142a' }"><UsergroupDeleteOutlined /> Deleted</a-breadcrumb-item>
			</a-breadcrumb>
			<a-button type="primary" :style="{ width: '150px' }"><DownloadOutlined />Download Excel</a-button>
			<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
		</div>

		<a-table
			size="middle"
			bordered
			@change="handleChange"
			:columns="columns"
			:data-source="data"
			:pagination="{
				current: pagination.current,
				pageSize: pagination.pageSize,
				total: pagination.total,
				position: ['topRight'],
				size: 'small',
				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
				showSizeChanger: false, 
			}"
			:loading="loading"
			:scroll="{ x: 1, y: height - 245 }"
			class="tableBody"
		>
			<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
				<div style="padding: 8px" v-if="column.dataIndex === 'login'">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {loginStartDate(e), (selectedKeys[0] = e)}" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {loginEndDate(e), (selectedKeys[1] = e)}" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.dataIndex == 'modifiedAt'">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {modifiedAtStartDate(e), (selectedKeys[0] = e)}" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {modifiedAtEndDate(e), (selectedKeys[1] = e)}" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else-if="column.dataIndex == 'createdAt'">
					<h4>Start Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[0]" @change="(e:any) => {startDate(e), (selectedKeys[0] = e)}" />
					<h4>End Date :</h4>
					<a-date-picker class="filterInput" format="DD-MMM-YYYY" :value="selectedKeys[1]" @change="(e:any) => {endDate(e), (selectedKeys[1] = e)}" />
					<a-button :style="{ marginRight: '15px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" :style="{ width: '45%' }" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">Ok </a-button>
				</div>
				<div style="padding: 8px" v-else>
					<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearch(selectedKeys, confirm, column.dataIndex)" />
					<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleReset(clearFilters, confirm, column.dataIndex)"> Reset </a-button>
					<a-button type="primary" @click="handleSearch(selectedKeys, confirm, column.dataIndex)">
						<template #icon><SearchOutlined /></template>
						Search
					</a-button>
				</div>
			</template>
			<template #bodyCell="{ text }">
				<template v-if="text">
					<div>
						{{ truncateText(text, 40) }}
					</div>
				</template>
			</template>
		</a-table>
		<ReportedNestedDrawer :closeNestedDrawer="closeNestedDrawer" :openNestedDrawer="openNestedDrawer" :innerData="innerData" :loading="loading" />
	</div>
</template>
<script lang="ts" setup>
	import { DownloadOutlined, UsergroupDeleteOutlined } from "@ant-design/icons-vue";
	import { Ref, ref } from "vue";
	import type { TableProps } from "ant-design-vue";
	import { SearchOutlined } from "@ant-design/icons-vue";
	import { ColumnsData } from "@/interface/Members.interface";
	import { getDeletedList, deletedMapData } from "@/services/api/user";
	import ReportedNestedDrawer from "./ReportedNestedDrawer.vue";
	import { truncateText, useScreenSize } from "@/utils";

	const filteredInfo = ref();
	const loading = ref(false);
	const userList = ref<any[]>([]);
	const data: any = ref([]);
	const innerData: any = ref([]);
	const userListFilter: any = ref({
		sort: "modified",
		search: null,
		searchBy: null,
		pageNo: 1,
		pageLimit: 20,
		accountStatus: false,
		deletedUser: true,
	});
	const filtered: any = ref([]);
	const pagination = ref({ pageSize: 20, total: 0, current: 0 });
	const openNestedDrawer = ref(false);

	const columnsData: ColumnsData[] = [
		{
			title: "Name",
			dataIndex: "nickName",
			key: "nickName",
			width: 300,
			customFilterDropdown: true,
			sorter: true,
		},
		{
			title: "Email Id",
			dataIndex: "identifier",
			key: "identifier",
			width: 300,
		},
		{
			title: "User Id",
			dataIndex: "id",
			key: "id",
			width: 200,
			align: "center",
		},
		{
			title: "Gender",
			dataIndex: "gender",
			key: "gender",
			width: 150,
			filters: [
				{ text: "Male", value: "m" },
				{ text: "Female", value: "f" },
			],
			align: "center",
		},
		{
			title: "Location",
			dataIndex: "place",
			key: "place",
			width: 200,
			customFilterDropdown: true,
			align: "center",
		},
		{
			title: "Last Login",
			dataIndex: "login",
			key: "login",
			align: "center",
			width: 150,
			loginCalendar: true,
			filterMultiple: true,
			customFilterDropdown: true,
		},
		{
			title: "Deleted",
			dataIndex: "modifiedAt",
			key: "modifiedAt",
			align: "center",
			width: 150,
			modifiedAtCalendar: true,
			filterMultiple: true,
			customFilterDropdown: true,
		},
		{
			title: "Created",
			dataIndex: "createdAt",
			key: "createdAt",
			align: "center",
			width: 150,
			customCalendar: true,
			filterMultiple: true,
			customFilterDropdown: true,
		},
	];

	const columns: Ref<ColumnsData[]> = ref<ColumnsData[]>(columnsData);
	const { height } = useScreenSize();

	const loginStartDate = (e: any) => {
		userListFilter.value.loginStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};
	const loginEndDate = (e: any) => {
		userListFilter.value.loginEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};
	const modifiedAtStartDate = (e: any) => {
		userListFilter.value.lastEditedStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};
	const modifiedAtEndDate = (e: any) => {
		userListFilter.value.lastEditedEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};
	const startDate = (e: any) => {
		userListFilter.value.createdStartDate = e.format(`YYYY-MM-DDT00:00:00.SSS[Z]`);
	};

	const endDate = (e: any) => {
		userListFilter.value.createdEndDate = e.format("YYYY-MM-DDT11:59:00.SSS[Z]");
	};
	const handleChange: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		if (filters.gender) {
			userListFilter.value.gender = filters.gender.join(",");
		}
		filteredInfo.value = filters;
		userListFilter.value.pageNo = Number(pagination.current);
		userListFilter.value.pageLimit = Number(pagination.pageSize);
		userListFilter.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilter.value.sortBy = sorter.order === undefined ? null : sorter.field;
		fetchData();
	};

	const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
		confirm();
		userListFilter.value.searchBy = dataIndex;
		userListFilter.value.search = selectedKeys[0];
		setTimeout(() => {
			fetchData();
		}, 1000);
	};

	const handleReset = (clearFilters: (arg0: { confirm: boolean }) => void, confirm: () => void, dataIndex: string) => {
		confirm();
		clearFilters({ confirm: true });
		if (dataIndex == "createdAt") {
			userListFilter.value.createdEndDate = null;
			userListFilter.value.createdStartDate = null;
		}
		if (dataIndex == "login") {
			userListFilter.value.loginEndDate = null;
			userListFilter.value.loginStartDate = null;
		}
		if (dataIndex == "modifiedAt") {
			userListFilter.value.lastEditedStartDate = null;
			userListFilter.value.lastEditedEndDate = null;
		}
		userListFilter.value.search = "";
		userListFilter.value.searchBy = "";
		fetchData();
	};

	const closeNestedDrawer = () => {
		openNestedDrawer.value = false;
		innerData.value = [];
	};

	const fetchData = async () => {
		try {
			loading.value = true;
			const apiRes: any = await getDeletedList(userListFilter.value);
			userList.value = apiRes?.items;
			data.value = await deletedMapData(userList.value);
			pagination.value.pageSize = Number(apiRes?.pageLimit);
			pagination.value.total = Number(apiRes?.TotalFilteredUser);
			pagination.value.current = Number(apiRes?.pageNo);
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	fetchData();
	const clearFilters = async () => {
		userListFilter.value = {
			pageNo: 1,
			pageLimit: 20,
			type: "demo",
			accountStatus: true,
		};
		await localStorage.setItem("userListFilter", JSON.stringify({ userList: { ...userListFilter.value }, filtered: filtered.value }));
		fetchData();
	};
</script>
<style scoped>
	.table-operations {
		display: flex;
		justify-content: flex-end;
		gap: 8px;
	}

	.table-operations > button {
		margin-left: 8px;
	}

	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}

	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
</style>
