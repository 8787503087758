<template>
	<div class="languagesContainer">
		<a-tabs default-active-key="1" :bordered="false" @change="activeTabChange" @tab-click="handleTabClick">
			<a-tab-pane key="1" tab="Languages List">
				<a-table
					size="middle"
					bordered
					@change="handleChangeLangueges"
					:columns="columns"
					:data-source="languages"
					:pagination="{
        				current: paginationForLanguages.current,
        				pageSize: paginationForLanguages.pageSize,
        				total: paginationForLanguages.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
					:loading="loading"
					:scroll="{ x: 1, y: height - 245 }"
					class="tableBody"
				>
					<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
						<div style="padding: 8px">
							<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearchForLangueges(selectedKeys, confirm, column.dataIndex)" />
							<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleResetForLangueges(clearFilters, confirm)"> Reset </a-button>
							<a-button type="primary" @click="handleSearchForLangueges(selectedKeys, confirm, column.dataIndex)">
								<template #icon><SearchOutlined /></template>
								Search
							</a-button>
						</div>
					</template>

					<template #index="{ text }">
						{{ text }}
					</template>

					<template #bodyCell="{ text, column, record }">
						<template v-if="column && column.dataIndex === 'language'">
							<a v-if="record.key !== 'EN'" @click="openEditLanguage(record)">
								{{ record.language }}
							</a>
							<p v-if="record.key === 'EN'">{{ record.language }}</p>
						</template>
					</template>

					<template #action="{ record }">
						<a-button type="primary" @click="openLanguageDetailsDrawer(record)" class="buttons"><FolderViewOutlined /> View</a-button>
						<a-button v-if="record.key != 'EN'" class="buttons" @click="removeLanguage(record)"><DeleteOutlined />Delete</a-button>
					</template>
				</a-table>

				<a-drawer :visible="languageDrawerVisible" @close="closeLanguageDrawer" width="35%">
					<template #title>
						<div style="display: flex; justify-content: space-between; align-items: center">
							<span>{{ languageDrawerTitle }}</span>
							<a-button type="primary" @click="saveLanguage" class="saveButton">Save</a-button>
						</div>
					</template>
					<a-form :model="currentRecord" layout="horizontal" :labelCol="{ span: 6 }">
						<a-form-item label="Language">
							<a-select v-model:value="currentRecord.language" placeholder="Select a Language" style="width: 100%" :filter-option="filterOption" showSearch @change="handleLanguageKeyChange">
								<a-select-option v-for="language in languagesName" :key="language.language" :value="language.name">
									{{ language.name }}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="Language Key">
							<div style="display: flex; gap: 10px">
								<a-input step="0" v-model:value="currentRecord.key" disabled />
							</div>
						</a-form-item>
						<a-form-item label="Country">
							<a-select v-model:value="currentRecord.country" placeholder="Select a country" style="width: 100%" :filter-option="filterOption" showSearch @change="handleIsoCodeChange">
								<a-select-option v-for="country in countries" :key="country.name" :value="currentRecord.name">
									{{ country.name }}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="ISO Code">
							<div style="display: flex; gap: 10px">
								<a-input step="0" v-model:value="currentRecord.isoCode" disabled />
							</div>
						</a-form-item>
					</a-form>
				</a-drawer>

				<a-drawer :visible="languageTranslateDrawerVisible" @close="closeLanguageTranslateDrawer" width="90%">
					<template #title>
						<div style="display: flex; justify-content: space-between; align-items: center">
							<span>Language Content</span>
							<div>
								<a-button type="primary" @click="showModalForDownloadJsonFile" class="buttons"><DownloadOutlined /> Download JSON</a-button>
								<a-button type="primary" @click="clearFiltersForLanguageContent">Clear Filters</a-button>
							</div>
						</div>
					</template>
					<a-table
						:dataSource="languageContentDetailsDrawerTableData"
						@change="handleChangeLanguageContent"
						:columns="languageDetailsDrawerTableColumns"
						:pagination="{
							current: paginationForLanguageContent.current,
							pageSize: paginationForLanguageContent.pageSize,
							total: paginationForLanguageContent.total,
							position: ['topRight'],
							size: 'small',
							showTotal: (total:any, range:any) => `${totalPublished} of ${total} Published | 📃 ${range[0]}-${range[1]} of ${total}`,
							showSizeChanger: false,
							
						}"
						:scroll="{ y: 800 }"
					>
						<template #status="{ record }">
							<div v-if="record.translate[0].value.length > 50">
								<a-tag v-if="record.status === 'Published'" color="green">{{ record.status }}</a-tag>
								<a-tag v-else color="red">{{ record.status }}</a-tag>
							</div>
							<a-switch v-else v-model:checked="record.translate[0].isPublished" @change="(value:any) => handleSwitchChange(record, value)" />
						</template>
						<template #action="{ record }">
							<a-button :disabled="selectedLanguageKey === 'EN'" @click="openEditLanguageContentDrawer(record)" type="primary" class="buttons"><EditOutlined /></a-button>
						</template>
						<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
							<div style="padding: 8px">
								<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearchForLanguageContent(selectedKeys, confirm, column.dataIndex)" />
								<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleResetForLanguageContent(clearFilters, confirm)"> Reset </a-button>
								<a-button type="primary" @click="handleSearchForLanguageContent(selectedKeys, confirm, column.dataIndex)">
									<template #icon><SearchOutlined /></template>
									Search
								</a-button>
							</div>
						</template>
						<template #bodyCell="{ text, column }">
							<template v-if="(column.dataIndex === 'base' || column.dataIndex === 'translateString') && text">
								{{ truncateText(text, 50) }}
							</template>
						</template>
					</a-table>
				</a-drawer>

				<a-drawer :visible="editLanguageContentDrawerVisible" @close="closeEditLanguageContentDrawer" width="50%">
					<template #title>
						<div style="display: flex; justify-content: space-between; align-items: center">
							<span>{{ editLanguageContentDrawerTitle }}</span>
							<a-button type="primary" @click="saveEditedLanguageContent" class="saveButton">Save</a-button>
						</div>
					</template>
					<a-form :v-model="editLanguageContentData" layout="horizontal" :labelCol="{ span: 3 }">
						<a-form-item label="Category ">
							<div style="display: flex; gap: 10px">
								<a-input v-model:value="editLanguageContentData.category" disabled />
							</div>
						</a-form-item>
						<a-form-item label="Key">
							<div style="display: flex; gap: 10px">
								<a-input v-model:value="editLanguageContentData.key" disabled />
							</div>
						</a-form-item>
						<a-form-item label="Default">
							<div style="display: flex; gap: 10px">
								<a-textarea :rows="9" v-model:value="editLanguageContentData.base" disabled />
							</div>
						</a-form-item>
						<a-form-item label="Publish ">
							<div style="display: flex; gap: 10px">
								<a-switch v-model:checked="editLanguageContentData.translate[0].isPublished" />
							</div>
						</a-form-item>
						<a-form-item label="Translate">
							<div style="display: flex; gap: 10px">
								<a-textarea :rows="7" v-model:value="editLanguageContentData.translateString" />
							</div>
						</a-form-item>
					</a-form>
				</a-drawer>
				<a-modal v-model:visible="jsonFileModelVisible" title="Download Dart File" @cancel="handleCancelJsonFileForm" class="dartFileModalContainer">
					<a-form layout="vertical" class="dartFileFormContainer">
						<div class="detailsContainer" :style="{ 'margin-bottom': '10px', height: '60px' }">
							<a-form-item label="Date:"></a-form-item>
							<a-input v-model:value="todayDateForDartFile" disabled :style="{ width: '250px', height: '40px' }" />
						</div>
						<div class="detailsContainer" :style="{ 'margin-bottom': '10px', height: '60px' }">
							<a-form-item label="Version Number:"></a-form-item>
							<a-input type="number" :style="{ width: '250px', height: '40px' }" v-model:value="version" />
						</div>
					</a-form>
					<template #footer>
						<a-button @click="handleCancelJsonFileForm">Cancel</a-button>
						<a-button type="primary" @click="handleSubmitJsonFileForm">Submit</a-button>
					</template>
				</a-modal>
			</a-tab-pane>

			<a-tab-pane key="2" tab="Content">
				<div v-if="activeTab === '2'">
					<a-table
						size="middle"
						bordered
						@change="handleChangeContent"
						:columns="contentColumns"
						:data-source="content"
						:pagination="{
        				current: paginationForContent.current,
        				pageSize: paginationForContent.pageSize,
        				total: paginationForContent.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
						:loading="loading"
						class="tableBody"
					>
						<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
							<div style="padding: 8px">
								<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearchForContent(selectedKeys, confirm, column.dataIndex)" />
								<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleResetForContent(clearFilters, confirm)"> Reset </a-button>
								<a-button type="primary" @click="handleSearchForContent(selectedKeys, confirm, column.dataIndex)">
									<template #icon><SearchOutlined /></template>
									Search
								</a-button>
							</div>
						</template>

						<template #index="{ text }">
							{{ text }}
						</template>
						<template #action="{ record }">
							<a-button @click="openEditContentDrawer(record)" type="primary" class="buttons"><EditOutlined /></a-button>
							<a-button @click="removeCategory(record)" type="primary" class="buttons"><DeleteOutlined /></a-button>
						</template>
						<template #bodyCell="{ text, column }">
							<template v-if="column.dataIndex === 'base' && text">
								{{ truncateText(text, 75) }}
							</template>
						</template>
					</a-table>
				</div>
				<a-drawer :visible="contentDrawerVisible" @close="closeEditContentDrawer" width="35%">
					<template #title>
						<div style="display: flex; justify-content: space-between; align-items: center">
							<span>{{ editContentDrawerTitle }}</span>
							<a-button type="primary" @click="saveContent" class="saveButton">Save</a-button>
						</div>
					</template>
					<a-form :model="contentData" layout="horizontal" :labelCol="{ span: 6 }">
						<a-form-item label="Category">
							<a-input v-model:value="contentData.category" />
						</a-form-item>
						<a-form-item label="key">
							<a-input v-model:value="contentData.key" />
						</a-form-item>
						<a-form-item label="Key Type">
							<a-select v-model:value="contentData.type" placeholder="Select a type">
								<a-select-option value="static">Static</a-select-option>
								<a-select-option value="function_base">Function Base</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="Dart Function Name" v-if="contentData.type == 'function_base'">
							<a-input v-model:value="contentData.functionName" />
						</a-form-item>
						<a-form-item label="Default">
							<a-textarea :rows="5" v-model:value="contentData.base" />
						</a-form-item>
					</a-form>
				</a-drawer>

				<a-modal v-model:visible="dartFileModalVisible" title="Download Dart File" @cancel="handleCancelDartFileForm" class="dartFileModalContainer">
					<a-form layout="vertical" class="dartFileFormContainer">
						<div class="detailsContainer" :style="{ 'margin-bottom': '10px', height: '60px' }">
							<a-form-item label="Date:"></a-form-item>
							<a-input v-model:value="todayDateForDartFile" disabled :style="{ width: '250px', height: '40px' }" />
						</div>
						<div class="detailsContainer" :style="{ 'margin-bottom': '10px', height: '60px' }">
							<a-form-item label="Version Number:"></a-form-item>
							<a-input type="number" :style="{ width: '250px', height: '40px' }" v-model:value="version" />
						</div>
					</a-form>
					<template #footer>
						<a-button @click="handleCancelDartFileForm">Cancel</a-button>
						<a-button type="primary" @click="handleSubmitDartFileForm">Submit</a-button>
					</template>
				</a-modal>
			</a-tab-pane>

			<a-tab-pane key="3" tab="Deleted Content">
				<a-table
					size="middle"
					bordered
					@change="handleChangeDeletedContentList"
					:columns="deletedContentColumn"
					:data-source="deletedContentList"
					:pagination="{
        				current: paginationForDeletedContentList.current,
        				pageSize: paginationForDeletedContentList.pageSize,
        				total: paginationForDeletedContentList.total,
        				position: ['topRight'],
        				size: 'small',
        				showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
        				showSizeChanger: false, 
      				}"
					:loading="loading"
					class="tableBody"
				>
					<template #customFilterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
						<div style="padding: 8px">
							<a-input :placeholder="`Search ${column.title}`" :value="selectedKeys[0]" class="filterInput" @change="(user:any) => setSelectedKeys(user.target.value ? [user.target.value] : [])" @pressEnter="handleSearchForDeletedContent(selectedKeys, confirm, column.dataIndex)" />
							<a-button :style="{ width: '90px', marginRight: '8px' }" @click="handleResetForDeletedContent(clearFilters, confirm)"> Reset </a-button>
							<a-button type="primary" @click="handleSearchForDeletedContent(selectedKeys, confirm, column.dataIndex)">
								<template #icon><SearchOutlined /></template>
								Search
							</a-button>
						</div>
					</template>
				</a-table>
			</a-tab-pane>

			<template #rightExtra>
				<div class="addClearButton">
					<div v-if="activeTab === '1'" class="table-operations-for-lang">
						<a-button type="primary" @click="openAddLanguageDrawer">ADD Languages</a-button>
						<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
					</div>
					<div v-if="activeTab === '2'" class="table-operations-for-content">
						<a-button type="primary" @click="showModalForDownloadDartFile"><DownloadOutlined /> Download Dart File</a-button>
						<a-button type="primary" @click="openAddContentDrawer">ADD Content</a-button>
						<a-button @click="clearFilters" type="primary" class="clear-filter">Clear filters</a-button>
					</div>
					<div v-if="activeTab === '3'" class="table-operations-for-content">
						<a-button @click="clearFiltersDeletedContent" type="primary" class="clear-filter">Clear filters</a-button>
					</div>
				</div>
			</template>
		</a-tabs>
	</div>
</template>

<script lang="ts" setup>
	import { createVNode, onBeforeMount, onMounted, ref } from "vue";
	import { getLanguagesList, deleteLanguage, updateLanguage, addLanguages, getCategoryApi, addContent, updateContent, updateTranslate, getTranslate, getKeyListApi, getCategoryListApi, deleteCategoryApi, getDeletedContentListApi, getLanguagesCategory, getDartFileVersionApi, updateDartVersionApi, updateContentPublish } from "@/services/api/user";
	import { DeleteOutlined, DownloadOutlined, EditOutlined, ExclamationCircleOutlined, FolderViewOutlined, SearchOutlined } from "@ant-design/icons-vue";
	import { Modal, TableProps } from "ant-design-vue";
	import { convertTimestamp, openNotificationWithIcon, truncateText, useScreenSize } from "@/utils";
	import { snippetsData } from "@/utils/ui_snippets";
	import countriesData from "@/json/languagesData.json";
	import languagesNameData from "@/json/languages.json";
	const countries = countriesData;
	const languagesName = languagesNameData;
	const languageDrawerVisible = ref(false);
	const currentRecord = ref<any>({});
	const languageDrawerTitle = ref("Edit Language");
	const editContentDrawerTitle = ref("Edit Content");
	const editLanguageContentDrawerTitle = ref("Edit Language Translate");
	const editLanguageContentDrawerVisible = ref(false);
	const languages = ref([]);
	const content = ref([]);
	const filteredInfoLanguages = ref();
	const filteredInfoContent = ref();
	const filteredInfoLanguageContent = ref();
	const filteredInfoDeletedContent = ref();
	const activeTab = ref("1");
	const selectedLanguageId = ref("");
	const selectedLanguageKey = ref("");
	const contentDrawerVisible = ref(false);
	const contentData = ref<any>({});
	const editLanguageContentData = ref<any>({});
	const languageTranslateDrawerVisible = ref(false);
	const languageContentDetailsDrawerTableData = ref([]);
	const dartFileModalVisible = ref(false);
	const jsonFileModelVisible = ref(false);
	const version = ref();
	const todayDateForDartFile = ref("");
	const categoryListFilters = ref<any[]>([]);
	const deletedContentList = ref([]);
	const totalPublished = ref(0);
	const totalUnpublished = ref(0);

	const userListPaginationLangueges = ref({ pageNo: 1, pageLimit: 500 });
	const userListPaginationContent = ref({ pageNo: 1, pageLimit: 500 });
	const userListPaginationLanguageContent = ref({ pageNo: 1, pageLimit: 500 });
	const userListPaginationDeletedContentList = ref({ pageNo: 1, pageLimit: 500 });
	const contentColumns: any = ref([]);
	const languageDetailsDrawerTableColumns: any = ref([]);

	const selectedCategory = ref(null);

	const userListFilterLangueges: any = ref({
		pageNo: 1,
		pageLimit: 500,
		sort: "modified",
		searchBy: null,
		search: null,
	});
	const userListFilterContent: any = ref({
		pageNo: 1,
		pageLimit: 500,
		sort: "modified",
		orderBy: "desc",
		searchBy: null,
		search: null,
	});

	const userListFilterLanguageContent: any = ref({
		pageNo: 1,
		pageLimit: 500,
		sort: "modified",
		orderBy: "desc",
		searchBy: null,
		search: null,
	});
	const userListFilterDeletedContentList: any = ref({
		pageNo: 1,
		pageLimit: 500,
		sort: "modified",
		searchBy: null,
		search: null,
	});

	const paginationForLanguages = ref({
		pageSize: 500,
		total: 0,
		current: 1,
		totalPages: 0,
	});

	const paginationForContent = ref({
		pageSize: 500,
		total: 0,
		current: 1,
		totalPages: 0,
	});

	const paginationForLanguageContent = ref({
		pageSize: 500,
		total: 0,
		current: 1,
		totalPages: 0,
	});

	const paginationForDeletedContentList = ref({
		pageSize: 500,
		total: 0,
		current: 1,
		totalPages: 0,
	});

	const loading = ref(false);
	const { height } = useScreenSize();

	const fetchDataLangueges = async () => {
		try {
			loading.value = true;
			const apiRes = await getLanguagesList({ pageNo: userListPaginationLangueges.value.pageNo, pageLimit: userListPaginationLangueges.value.pageLimit }, { ...userListFilterLangueges.value });
			languages.value = apiRes?.items || [];
			paginationForLanguages.value.total = apiRes?.pagination.total;
			paginationForLanguages.value.current = apiRes?.pagination.currentPage;
			paginationForLanguages.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Failed to fetch languages");
			console.error("Error fetching data:", error);
		}
	};
	fetchDataLangueges();

	const fetchDataContent = async () => {
		try {
			loading.value = true;
			const apiRes = await getCategoryApi({ pageNo: userListPaginationContent.value.pageNo, pageLimit: userListPaginationContent.value.pageLimit }, { ...userListFilterContent.value });
			content.value = apiRes?.items || [];
			paginationForContent.value.total = apiRes?.pagination.totalCount;
			paginationForContent.value.current = apiRes?.pagination.currentPage;
			paginationForContent.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Failed to fetch Content");
			console.error("Error fetching data:", error);
		}
	};

	const fetchDataLanguageDetailsDrawer = async (selectedLanguageId: string) => {
		try {
			loading.value = true;
			const apiRes = await getLanguagesCategory({ pageNo: userListPaginationLanguageContent.value.pageNo, pageLimit: userListPaginationLanguageContent.value.pageLimit }, { ...userListFilterLanguageContent.value, selectedLanguageId });
			languageContentDetailsDrawerTableData.value = apiRes?.items || [];
			totalUnpublished.value = apiRes?.unPublish;
			totalPublished.value = apiRes?.publish;
			languageContentDetailsDrawerTableData.value.forEach((item: any) => {
				if (item.translate) {
					item.translate.forEach((translation: any) => {
						if (translation.languageId === selectedLanguageId) {
							item.translateString = translation.value;
							item.status = translation.isPublished ? "Published" : "Pending";
						}
					});
				}
			});

			paginationForLanguageContent.value.total = apiRes?.pagination.totalCount;
			paginationForLanguageContent.value.current = apiRes?.pagination.currentPage;
			paginationForLanguageContent.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Failed to fetch Content");
			console.error("Error fetching data:", error);
		}
	};

	const handleSwitchChange = async (record: any, value: boolean) => {
		try {
			loading.value = true;
			const data = { isPublished: value };
			await updateContentPublish(record._id, record.translate[0].languageId, data);
			record.translate[0].isPublished = value;
			if (value) {
				totalPublished.value += 1;
				totalUnpublished.value -= 1;
			} else {
				totalPublished.value -= 1;
				totalUnpublished.value += 1;
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
			openNotificationWithIcon("error", "Failed to update status");
			console.error("Failed to update content publish status:", error);
		}
	};

	const fetchDataDeletedContent = async () => {
		try {
			loading.value = true;
			const apiRes = await getDeletedContentListApi({ pageNo: userListPaginationDeletedContentList.value.pageNo, pageLimit: userListPaginationDeletedContentList.value.pageLimit }, { ...userListFilterDeletedContentList.value });
			deletedContentList.value = apiRes?.items || [];
			deletedContentList.value =
				apiRes?.items.map((item: any) => ({
					...item,
					deletedAt: convertTimestamp(item.deletedAt),
				})) || [];
			paginationForDeletedContentList.value.total = apiRes?.pagination.totalCount;
			paginationForDeletedContentList.value.current = apiRes?.pagination.currentPage;
			paginationForDeletedContentList.value.totalPages = apiRes?.pagination.totalPages;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	const handleTabClick = (key: string) => {
		if (key === "2") {
			fetchDataContent();
		}
		if (key === "3") {
			fetchDataDeletedContent();
		}
	};

	const downloadJson = async () => {
		const json = await getTranslate(selectedLanguageId.value);
		let fileName = "";
		languages.value.forEach((lang: any) => {
			if (lang._id === selectedLanguageId.value) {
				fileName = lang.key;
			}
		});
		if (json.item) {
			const defaultValue = {
				generalText_version_number: version.value,
				generalText_version_date: todayDateForDartFile.value,
				...json.item,
			};
			const jsonStr = JSON.stringify(defaultValue, null, 4);
			const blob = new Blob([jsonStr], { type: "application/json" });
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${fileName.toLocaleLowerCase()}.json`;
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
			jsonFileModelVisible.value = false;
		}
	};

	const getKeyList = async () => {
		try {
			const apiRes = await getKeyListApi();
			const { item } = apiRes;
			let outputStatic = `// Version\nstatic final generalText_version_number				=   LanguageTranslation().getText('generalText_version_number');\nstatic final generalText_version_date				=   LanguageTranslation().getText('generalText_version_date');\n\n\n`;
			let outputFunction = "";
			const functionIncludes: any[] = [];
			for (let i = 0; i < item.length; i++) {
				const itemDetails = item[i];
				if (itemDetails.type === "static") {
					if ((item[i - 1] && itemDetails.category !== item[i - 1].category) || i === 0) {
						outputStatic += "\n" + `// ${itemDetails.category}` + "\n";
					}
					const string = `static final ${itemDetails.key}				=   LanguageTranslation().getText('${itemDetails.key}');`;
					outputStatic += string + "\n";
				}
				if (itemDetails.type === "function_base" && itemDetails.functionName) {
					const string = `static ${itemDetails.functionName}(text)      { return LanguageTranslation().getText("${itemDetails.functionName}"+text); }`;
					if (!functionIncludes.includes(itemDetails.functionName)) {
						outputFunction += string + "\n";
						functionIncludes.push(itemDetails.functionName);
					}
				}
			}
			const output = (await snippetsData({ time: todayDateForDartFile.value, version: version.value })) + "\n\n\n" + outputStatic + "\n\n\n" + outputFunction + "\n\n\n" + "}";
			const blob = new Blob([output], { type: "application/json" });
			const url = URL.createObjectURL(blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = "ui_snippets.dart";
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
			dartFileModalVisible.value = false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const showModalForDownloadDartFile = () => {
		dartFileModalVisible.value = true;
		getVersionForDartFile();
	};

	const showModalForDownloadJsonFile = () => {
		jsonFileModelVisible.value = true;
		getVersionForDartFile();
	};

	const handleSubmitDartFileForm = async () => {
		if (!version.value) {
			openNotificationWithIcon("error", "Please enter version number");
			return;
		}
		await updateDartVersion();
		await getKeyList();
	};

	const handleSubmitJsonFileForm = async () => {
		if (!version.value) {
			openNotificationWithIcon("error", "Please enter version number");
			return;
		}
		await updateDartVersion();
		await downloadJson();
	};

	const handleCancelDartFileForm = () => {
		dartFileModalVisible.value = false;
	};

	const handleCancelJsonFileForm = () => {
		jsonFileModelVisible.value = false;
	};

	const dateForDartFile = () => {
		const today = new Date();
		const yyyy = today.getFullYear();
		const mm = String(today.getMonth() + 1).padStart(2, "0");
		const dd = String(today.getDate()).padStart(2, "0");
		todayDateForDartFile.value = `${dd}-${mm}-${yyyy}`;
	};
	dateForDartFile();

	const columns = [
		{
			title: "Language",
			dataIndex: "language",
			key: "language",
			visible: true,
			customFilterDropdown: true,
			width: 100,
		},
		{
			title: "Key",
			dataIndex: "key",
			key: "key",
			visible: true,
			customFilterDropdown: true,
			width: 100,
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			customFilterDropdown: true,
			width: 100,
		},
		{
			title: "ISO Code",
			dataIndex: "isoCode",
			key: "isoCode",
			customFilterDropdown: true,
			width: 100,
		},
		{
			title: "Action",
			key: "action",
			width: 150,
			slots: { customRender: "action" },
		},
	];

	contentColumns.value = [
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			filters: categoryListFilters.value,
			filterMultiple: false,
			width: 200,
			sorter: true,
		},
		{
			title: "Key",
			dataIndex: "key",
			key: "key",
			customFilterDropdown: true,
			width: 50,
			sorter: true,
		},
		{
			title: "Default",
			dataIndex: "base",
			key: "base",
			width: 400,
		},
		{
			title: "Action",
			key: "action",
			width: 200,
			slots: { customRender: "action" },
		},
	];

	const CategoryFilters = async () => {
		try {
			const apiRes = await getCategoryListApi();
			categoryListFilters.value = (await apiRes.item.map((item: any) => ({ text: item.category, value: item.category }))) || [];
			contentColumns.value[0].filters = categoryListFilters.value;
			languageDetailsDrawerTableColumns.value[0].filters = categoryListFilters.value;
			return categoryListFilters.value;
		} catch (error) {
			console.log(error);
			return [];
		}
	};
	onMounted(CategoryFilters);

	languageDetailsDrawerTableColumns.value = [
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			filters: categoryListFilters.value,
			width: 200,
			sorter: true,
			filterMultiple: false,
		},
		{
			title: "Key",
			dataIndex: "key",
			key: "key",
			customFilterDropdown: true,
			width: 200,
			sorter: true,
		},
		{
			title: "Default",
			dataIndex: "base",
			key: "base",
			width: 300,
		},
		{
			title: "Translate",
			dataIndex: "translateString",
			key: "translate",
			width: 300,
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			filters: [
				{ text: "Publish", value: "publish" },
				{ text: "unPublish", value: "unPublish" },
			],
			filterMultiple: false,
			slots: { customRender: "status" },
			width: 100,
		},
		{
			title: "Action",
			key: "action",
			width: 100,
			slots: { customRender: "action" },
		},
	];

	const deletedContentColumn = [
		{
			title: "Deleted At",
			dataIndex: "deletedAt",
			key: "deletedAt",
			width: 200,
		},
		{
			title: "Category",
			dataIndex: "category",
			key: "category",
			width: 200,
			customFilterDropdown: true,
		},
		{
			title: "Key",
			dataIndex: "key",
			key: "key",
			width: 200,
			customFilterDropdown: true,
		},
		{
			title: "Key Type",
			dataIndex: "type",
			key: "type",
			width: 200,
			// filters: [
			// 	{ text: "Function Base", value: "function_base" },
			// 	{ text: "Static", value: "static" },
			// ],
			customFilterDropdown: true,
		},
	];

	const activeTabChange = (key: string) => {
		activeTab.value = key;
	};

	const clearFilters = () => {
		if (activeTab.value === "1") {
			clearFiltersForLang();
		} else {
			clearFiltersForContent();
		}
	};

	const clearFiltersForLang = () => {
		userListFilterLangueges.value.searchBy = null;
		userListFilterLangueges.value.search = null;
		fetchDataLangueges();
	};

	const clearFiltersForContent = () => {
		userListFilterContent.value.search = null;
		userListFilterContent.value.searchBy = null;
		fetchDataContent();
	};

	const clearFiltersForLanguageContent = () => {
		userListFilterLanguageContent.value.search = null;
		userListFilterLanguageContent.value.searchBy = null;
		fetchDataLanguageDetailsDrawer(selectedLanguageId.value);
	};

	const clearFiltersDeletedContent = () => {
		userListFilterDeletedContentList.value.search = null;
		userListFilterDeletedContentList.value.searchBy = null;
		fetchDataDeletedContent();
	};

	const capitalizeFirstLetter = (str: string) => {
		return str.charAt(0).toUpperCase() + str.slice(1);
	};

	const filterOption = (inputValue: any, option: any) => {
		try {
			let optionValue = option.value;
			if (typeof optionValue === "string") {
				return optionValue.toLowerCase().startsWith(inputValue.toLowerCase());
			}
			return false;
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleIsoCodeChange = (countryName: string) => {
		try {
			const selectedCountry = countries.find((country: any) => country.name === countryName);
			if (selectedCountry) {
				currentRecord.value.isoCode = selectedCountry.iso_code.slice(0, 2);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const handleLanguageKeyChange = (language: string) => {
		try {
			const selectedLanguage = languagesName.find((lang: any) => lang.name === language);
			if (selectedLanguage) {
				currentRecord.value.key = selectedLanguage.language.toUpperCase();
			}
		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};

	const openEditLanguage = (record: any) => {
		currentRecord.value = { ...record };
		languageDrawerVisible.value = true;
		languageDrawerTitle.value = "Edit Language";
	};

	const openAddLanguageDrawer = () => {
		languageDrawerVisible.value = true;
		currentRecord.value = {};
		languageDrawerTitle.value = "Add Language";
	};

	const openAddContentDrawer = () => {
		contentDrawerVisible.value = true;
		contentData.value = {};
		editContentDrawerTitle.value = "Add Content";
	};

	const openEditContentDrawer = (record: any) => {
		contentData.value = { ...record };
		contentDrawerVisible.value = true;
		editContentDrawerTitle.value = "Edit Content";
	};
	const openLanguageDetailsDrawer = async (record: any) => {
		selectedLanguageKey.value = record.key;
		selectedLanguageId.value = record._id;
		await fetchDataLanguageDetailsDrawer(record._id);
		languageTranslateDrawerVisible.value = true;
	};

	// const openEditLanguageContentDrawer = (record: any) => {
	// 	editLanguageContentData.value = { ...record };
	// 	editLanguageContentDrawerVisible.value = true;
	// 	editLanguageContentDrawerTitle.value = "Edit language Translate";
	// };
	const openEditLanguageContentDrawer = (record: any) => {
		editLanguageContentData.value = { ...record, isPublished: record.isPublished || false };
		editLanguageContentDrawerVisible.value = true;
		editLanguageContentDrawerTitle.value = "Edit language Translate";
	};
	const closeLanguageDrawer = () => {
		languageDrawerVisible.value = false;
	};

	const closeEditContentDrawer = () => {
		contentDrawerVisible.value = false;
	};

	const closeLanguageTranslateDrawer = () => {
		languageTranslateDrawerVisible.value = false;
	};

	const closeEditLanguageContentDrawer = () => {
		editLanguageContentDrawerVisible.value = false;
	};

	const handleSearchForLangueges = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		userListFilterLangueges.value.searchBy = dataIndex;
		userListFilterLangueges.value.search = selectedKeys[0].trim();
		fetchDataLangueges();
	};

	const handleResetForLangueges = (clearFilters: any, confirm: any) => {
		clearFilters();
		confirm();
		userListFilterLangueges.value.searchBy = null;
		userListFilterLangueges.value.search = null;
		fetchDataLangueges();
	};

	const handleSearchForContent = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		userListFilterContent.value.searchBy = dataIndex;
		userListFilterContent.value.search = selectedKeys[0].trim();
		console.log(dataIndex, selectedKeys[0], 917);
		if (dataIndex === "category") {
			userListFilterContent.value.searchBy = "category";
			userListFilterContent.value.search = selectedKeys[0];
		}

		fetchDataContent();
	};

	const handleResetForContent = (clearFilters: any, confirm: any) => {
		clearFilters();
		confirm();
		userListFilterContent.value.searchBy = null;
		userListFilterContent.value.search = null;
		selectedCategory.value = null;
		fetchDataContent();
	};

	const handleSearchForLanguageContent = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		userListFilterLanguageContent.value.searchBy = dataIndex;
		userListFilterLanguageContent.value.search = selectedKeys[0].trim();
		fetchDataLanguageDetailsDrawer(selectedLanguageId.value);
	};

	const handleResetForLanguageContent = (clearFilters: any, confirm: any) => {
		clearFilters();
		confirm();
		userListFilterLanguageContent.value.searchBy = null;
		userListFilterLanguageContent.value.search = null;
		fetchDataLanguageDetailsDrawer(selectedLanguageId.value);
	};

	const handleSearchForDeletedContent = (selectedKeys: any, confirm: any, dataIndex: any) => {
		confirm();
		userListFilterDeletedContentList.value.searchBy = dataIndex;
		userListFilterDeletedContentList.value.search = selectedKeys[0].trim();
		fetchDataDeletedContent();
	};

	const handleResetForDeletedContent = (clearFilters: any, confirm: any) => {
		clearFilters();
		confirm();
		userListFilterDeletedContentList.value.searchBy = null;
		userListFilterDeletedContentList.value.search = null;
		fetchDataDeletedContent();
	};

	const handleChangeLangueges: TableProps["onChange"] = (pagination, filters) => {
		filteredInfoLanguages.value = filters;
		userListPaginationLangueges.value.pageNo = Number(pagination.current);
		userListFilterLangueges.value.pageNo = Number(pagination.current);
		userListPaginationLangueges.value.pageLimit = Number(pagination.pageSize);
		fetchDataLangueges();
	};

	const handleChangeContent: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		filteredInfoContent.value = filters;
		if (filters.category) {
			userListFilterContent.value.searchBy = "category";
			userListFilterContent.value.search = filters.category[0];
		}
		userListPaginationContent.value.pageNo = Number(pagination.current);
		userListFilterContent.value.pageNo = Number(pagination.current);
		userListPaginationContent.value.pageLimit = Number(pagination.pageSize);
		userListFilterContent.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilterContent.value.sortBy = sorter.order === undefined ? null : sorter.field;
		fetchDataContent();
	};

	const handleChangeLanguageContent: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		filteredInfoContent.value = filters;
		if (filters.category) {
			userListFilterLanguageContent.value.searchBy = "category";
			userListFilterLanguageContent.value.search = filters.category[0];
		}
		if (filters.status) {
			userListFilterLanguageContent.value.searchBy = "status";
			userListFilterLanguageContent.value.search = filters.status[0];
		}
		userListPaginationLanguageContent.value.pageNo = Number(pagination.current);
		userListFilterLanguageContent.value.pageNo = Number(pagination.current);
		userListPaginationLanguageContent.value.pageLimit = Number(pagination.pageSize);
		userListFilterLanguageContent.value.orderBy = sorter.order === "ascend" ? "asc" : sorter.order === "descend" ? "desc" : null;
		userListFilterLanguageContent.value.sortBy = sorter.order === undefined ? null : sorter.field;
		fetchDataLanguageDetailsDrawer(selectedLanguageId.value);
	};

	const handleChangeDeletedContentList: TableProps["onChange"] = (pagination, filters, sorter: any) => {
		filteredInfoDeletedContent.value = filters;
		userListPaginationDeletedContentList.value.pageNo = Number(pagination.current);
		userListFilterDeletedContentList.value.pageNo = Number(pagination.current);
		userListPaginationDeletedContentList.value.pageLimit = Number(pagination.pageSize);
		fetchDataDeletedContent();
	};

	const saveLanguage = async () => {
		try {
			loading.value = true;
			if (!currentRecord.value.language || !currentRecord.value.key || !currentRecord.value.country || !currentRecord.value.isoCode) {
				openNotificationWithIcon("error", "Please fill all the fields");
				return;
			}
			if (typeof currentRecord.value.language != "string" || typeof currentRecord.value.key != "string" || typeof currentRecord.value.country != "string" || typeof currentRecord.value.isoCode != "string") {
				openNotificationWithIcon("error", "Please enter valid data");
				return;
			}
			const languageCapitalize = capitalizeFirstLetter(currentRecord.value.language);
			currentRecord.value.language = languageCapitalize;
			if (currentRecord.value._id) {
				try {
					const { _id, ...data } = currentRecord.value;
					await updateLanguage(_id, data);
					openNotificationWithIcon("success", "Language updated successfully");
				} catch (error: any) {
					openNotificationWithIcon("error", error.response.data.message);
					console.log(error);
				}
			} else {
				try {
					await addLanguages(currentRecord.value);
					openNotificationWithIcon("success", "Language added successfully");
				} catch (error: any) {
					openNotificationWithIcon("error", error.response.data.message);
					console.log(error);
				}
			}
			closeLanguageDrawer();
			fetchDataLangueges();
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error saving language:", error);
		}
	};

	const saveContent = async () => {
		try {
			loading.value = true;
			if (!contentData.value.category || !contentData.value.key || !contentData.value.base || !contentData.value.type) {
				openNotificationWithIcon("error", "Please fill all the fields");
				return;
			}
			if (contentData.value.type === "function_base" && !contentData.value.functionName) {
				openNotificationWithIcon("error", "Please fill dart function name");
				return;
			}
			if (contentData.value.type === "function_base" && !contentData.value.functionName.endsWith("_")) {
				openNotificationWithIcon("error", "Function name should end with _");
				return;
			}
			if (contentData.value.type === "function_base" && !contentData.value.key.startsWith(contentData.value.functionName)) {
				openNotificationWithIcon("error", "function name should be start with Key's text");
				return;
			}
			if (contentData.value._id) {
				try {
					const { _id, ...data } = contentData.value;
					await updateContent(_id, data);
					openNotificationWithIcon("success", "Content updated successfully");
				} catch (error: any) {
					openNotificationWithIcon("error", error.response.data.message);
					console.error("Error updating content:", error);
				}
			} else {
				try {
					await addContent(contentData.value);
					openNotificationWithIcon("success", "Content added successfully");
				} catch (error: any) {
					openNotificationWithIcon("error", error.response.data.message);
					console.error("Error adding content:", error);
				}
			}
			closeEditContentDrawer();
			fetchDataContent();
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error saving content:", error);
		} finally {
			loading.value = false;
		}
	};

	const saveEditedLanguageContent = async () => {
		try {
			loading.value = true;
			const contentId = editLanguageContentData.value._id;
			const languageId = selectedLanguageId.value;
			const translateString = editLanguageContentData.value.translateString;
			const isPublished = editLanguageContentData.value.translate[0].isPublished;
			await updateTranslate(contentId, languageId, { translate: translateString, isPublished: isPublished });
			closeEditLanguageContentDrawer();
			openNotificationWithIcon("success", "Language content updated successfully");

			await fetchDataLanguageDetailsDrawer(selectedLanguageId.value);
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error saving content:", error);
			openNotificationWithIcon("error", "Failed to update content");
		}
	};

	const getVersionForDartFile = async () => {
		try {
			loading.value = true;
			const apiRes = await getDartFileVersionApi();
			version.value = apiRes.item;
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	const updateDartVersion = async () => {
		try {
			loading.value = true;
			const apiRes = await updateDartVersionApi({ value: version.value });
			if (apiRes.valid) {
				openNotificationWithIcon("success", "Dart version updated successfully");
			} else {
				openNotificationWithIcon("error", "Failed to update Dart version");
			}
			loading.value = false;
		} catch (error) {
			loading.value = false;
			console.error("Error fetching data:", error);
		}
	};

	const removeLanguage = async (record: any) => {
		let strings = languages.value
			.filter((lang: any) => lang?.languages !== record?.languages)
			.map((lang: any) => {
				return lang.languages;
			});
		Modal.confirm({
			title: "Are you sure to delete Language?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				try {
					const deleteResult = await deleteLanguage(record._id);
					if (deleteResult.valid) {
						fetchDataLangueges();
						openNotificationWithIcon("success", "Languages deleted successfully");
					} else {
						openNotificationWithIcon("error", "Failed to delete Languages");
					}
				} catch (error) {
					console.error("Error deleting IP Address:", error);
					openNotificationWithIcon("error", "An error occurred while deleting Languages");
				}
			},
		});
	};

	const removeCategory = async (record: any) => {
		Modal.confirm({
			title: "Are you sure to delete Content?",
			icon: createVNode(ExclamationCircleOutlined),
			okText: "Yes",
			okType: "danger",
			cancelText: "No",
			async onOk() {
				try {
					const deleteResult = await deleteCategoryApi(record._id);
					if (deleteResult.valid) {
						fetchDataContent();
						openNotificationWithIcon("success", "Content deleted successfully");
					} else {
						openNotificationWithIcon("error", "Failed to delete Content");
					}
				} catch (error) {
					console.error("Error deleting IP Address:", error);
					openNotificationWithIcon("error", "An error occurred while deleting Content");
				}
			},
		});
	};
</script>

<style scoped>
	.languagesContainer {
		padding: 20px 40px;
	}
	.table-operations {
		display: flex;
		justify-content: flex-end;
		margin-bottom: -32px;
		gap: 8px;
	}
	.heading {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;
		color: #44142a !important;
	}
	.buttons {
		margin-right: 25px;
	}
	.centered-button {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
	.filterInput {
		margin-bottom: 8px;
		display: block;
	}
	.saveButton {
		width: 100px;
	}
	.addClearButton {
		display: flex;
		justify-content: flex-end;
	}
	.table-operations-for-lang {
		display: flex;
		gap: 10px;
	}
	.table-operations-for-content {
		display: flex;
		gap: 10px;
	}
	.detailsContainer {
		display: flex;
		justify-content: space-between;
		font-size: 14px;
	}
	.dartFileModalContainer {
		width: 600px;
		padding: 20px;
	}
	.dartFileFormContainer {
		margin: 30px;
	}
</style>
