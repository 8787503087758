<template>
	<a-drawer title="Reporters" width="60%" :open="openNestedDrawer" @close="closeNestedDrawer">
		<h3 class="heading">User Details</h3>
		<div class="mainDiv">
			<div>
				<div class="flexDisplay">
					<div style="display: flex; justify-content: end; width: 90px">
						<h3>Name :</h3>
					</div>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails.nickName }} </a-typography-text>
				</div>
				<div class="flexDisplay">
					<div style="display: flex; justify-content: end; width: 90px">
						<h3>Email Id :</h3>
					</div>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails.identifier }} </a-typography-text>
				</div>
				<div class="flexDisplay">
					<div style="display: flex; justify-content: end; width: 90px">
						<h3>User Id :</h3>
					</div>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails._id }} </a-typography-text>
				</div>
			</div>
			<div>
				<div class="flexDisplay">
					<h3>Joined :</h3>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails.joined && dayjs.utc(userDetails.joined).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
				</div>
				<div class="flexDisplay" v-if="userDetails.login">
					<h3>Last Login :</h3>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails.login && dayjs.utc(userDetails.login).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
				</div>
				<div class="flexDisplay">
					<h3>Last Updated :</h3>
					<a-typography-text class="ant-form-text" type="secondary">{{ userDetails.modifiedAt && dayjs.utc(userDetails.modifiedAt).format("DD-MMM-YYYY HH:mm") }} </a-typography-text>
				</div>
			</div>
		</div>
		<a-table
			size="middle"
			:pagination="{
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: pagination.total,
                position: ['topRight'],
                size: 'small',
                showTotal: (total:any, range:any) => `${range[0]}-${range[1]} of ${total}`,
                showSizeChanger: false, 
            }"
			bordered
			:columns="innerColumnsData"
			:data-source="data"
			:loading="loading"
			class="tableBody"
			@change="handleChange"
		>
			<template #bodyCell="{ text, column }">
				<template v-if="column && column.dataIndex === 'text' && text.length > 17">
					<div @click="showModal(text)">
						{{ truncateText(text, 17) }}
					</div>
				</template>
			</template>
		</a-table>
		<a-modal :open="openTextModal" @cancel="() => (openTextModal = false)">{{ reportText }} </a-modal>
		<template #footer>
			<div class="footerClass">
				<a-button size="large" :style="{ width: '15%' }" type="primary" @click="closeNestedDrawer">Cancel</a-button>
			</div>
		</template>
	</a-drawer>
</template>

<script setup lang="ts">
	import { ColumnsData } from "@/interface/Members.interface";
	import { convertTimestamp, truncateText } from "@/utils";
	import { ref } from "vue";
	import dayjs from "dayjs";
	const pagination = ref({ pageSize: 20, total: 0, current: 1 });
	const userListPagination = ref({ pageNo: 1, pageLimit: 20 });
	const data = ref([]);
	const openTextModal = ref(false);
	const reportText = ref();
	const { openNestedDrawer, closeNestedDrawer, loading, userDetails, selectedId } = defineProps(["openNestedDrawer", "closeNestedDrawer", "loading", "userDetails", "selectedId"]);
	import { getReportedListData } from "@/services/api/user";
	import { TableProps } from "ant-design-vue";

	const innerColumnsData: ColumnsData[] = [
		{
			title: "Reported by",
			dataIndex: "nickName",
			key: "nickName",
			align: "center",
		},
		{
			title: "User ID",
			dataIndex: "_id",
			key: "_id",
		},
		{
			title: "Email Id",
			dataIndex: "identifier",
			key: "identifier",
			align: "center",
		},
		{
			title: "Reported for",
			dataIndex: "reason",
			key: "reason",
			align: "center",
		},
		{
			title: "Report Dt",
			dataIndex: "createdAt",
			key: "createdAt",
			align: "center",
		},
		{
			title: "Message from User",
			dataIndex: "text",
			key: "text",
			align: "center",
		},
	];

	const showModal = (text: string) => {
		openTextModal.value = true;
		reportText.value = text;
	};

	const fetchData = async () => {
		try {
			const apiRes = await getReportedListData(selectedId, { ...userListPagination.value });
			data.value = apiRes.item.map((item: any) => ({
				nickName: item.user.nickName,
				_id: item.user._id,
				identifier: item.user.identifier,
				createdAt: convertTimestamp(item.createdAt),
				reason: item.reason,
				text: item.text || "",
			}));
			pagination.value.total = apiRes.pagination.total;
			pagination.value.current = userListPagination.value.pageNo;
			loading.value = false;
		} catch (error) {
			console.error(error);
		}
	};
	fetchData();

	const handleChange: TableProps["onChange"] = (pagination, filters) => {
		userListPagination.value.pageNo = Number(pagination.current);
		userListPagination.value.pageLimit = Number(pagination.pageSize);
		fetchData();
	};
</script>
<style scoped>
	h3 {
		margin-right: 15px;
		margin-left: auto;
	}
	.ant-form-text {
		/* margin-left: auto; */
	}
	.flexDisplay {
		display: flex;
	}
	.footerClass {
		display: flex;
		justify-content: end;
	}

	.secondDiv {
		display: grid;
		justify-content: end;
	}

	.mainDiv {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.firstSubDiv {
		display: flex;
		justify-content: space-between;
		width: 18%;
		min-width: 200px;
		padding-top: 30px;
	}

	.secondSubDiv {
		display: flex;
		justify-content: space-between;
		min-width: 200px;
		width: 22%;
	}

	.heading {
		text-align: center;
		font-size: 30px;
	}
</style>
