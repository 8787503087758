import axios, { AxiosRequestConfig } from "axios";
import { BACKEND_BASE_URL, api, httpAuth } from "../.config";
import { toDynamicKey } from "../utils/tokenGenerate";
import { notEmptyString, openNotificationWithIcon } from "@/utils";
import router from "@/router";
const Index = {
	init() {
		axios.defaults.baseURL = BACKEND_BASE_URL;
	},

	async post<T>(endpoint: string, data?: any) {
		try {
			const response = await axios.post(endpoint, data, {
				headers: { token: toDynamicKey() },
			});
			return response.data;
		} catch (error: any) {
			throw error;
		}
	},

	async put<T>(endpoint: string, data?: any) {
		try {
			const response = await axios.put(endpoint, data, {
				headers: { token: toDynamicKey() },
			});
			return response.data;
		} catch (error: any) {
			throw error;
		}
	},
};

export default Index;

export const buildOptions = (isFormData = false, userId = null, callback: any = null) => {
	const { key } = api;
	const options: any = {};
	const headers: any = {};
	const uid: any = notEmptyString(userId) ? userId : "";

	if (isFormData) {
		headers["X-Requested-With"] = "XMLHttpRequest";
		headers["Content-Type"] = "multipart/form-data";
		if (callback instanceof Function) {
			options.onUploadProgress = callback;
		}
	}

	if (httpAuth) {
		if (notEmptyString(httpAuth.user, 3) && notEmptyString(httpAuth.pass, 3)) {
			const credentials = btoa(httpAuth.user + ":" + httpAuth.pass);
			headers.Authorization = "Basic " + credentials;
		}
	}
	if (key) {
		if (key.length > 1) {
			headers.token = toDynamicKey(uid);
		}
	}
	options.headers = headers;
	return options;
};

export const fetchData = async (path: string, deleteMode = false, userId = "") => {
	let result: any = {};
	const func = deleteMode !== true ? axios.get : axios.delete;
	await func(`${api.base}${path}`, buildOptions(false, null, userId))
		.then((res) => {
			result = res;
		})
		.catch((e) => {
			openNotificationWithIcon("error", e?.response?.data?.message);
			result.error = e;
		});
	return result;
};
